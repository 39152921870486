import React, { useState, useEffect } from "react";
import { Music } from "react-feather";
import Card from "components/card";
import DataTable from "components/dataTable/reactDataTable";
import { get } from "services/api_Helpers";
import ReactPaginate from "react-paginate";
import AudioPlayerModal from "components/common/AudioPlayerModal";

const AllAffirmations = () => {
  const [sessions, setSessions] = useState([]);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [emailFilter, setEmailFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAudioUrl, setCurrentAudioUrl] = useState(null);

  useEffect(() => {
    fetchSessions();
  }, [currentPage]);

  useEffect(() => {
    let filtered = sessions;

    if (emailFilter) {
      filtered = filtered.filter((session) =>
        session.email.toLowerCase().includes(emailFilter.toLowerCase())
      );
    }

    if (statusFilter) {
      filtered = filtered.filter(
        (session) => session.paymentStatus.toLowerCase() === statusFilter.toLowerCase()
      );
    }

    setFilteredSessions(filtered);
  }, [emailFilter, statusFilter, sessions]);

  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await get(`/all-sessions?page=${currentPage + 1}&limit=10`);
      setSessions(response.data.sessions);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayClick = (audioUrl) => {
    setCurrentAudioUrl(audioUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentAudioUrl(null);
  };

  const getPaymentStatusColor = (status) => {
    return status === "paid" ? "text-green-500" : "text-red-500";
  };

  const columns = [
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Coupon Code",
      selector: (row) =>
        row.coupon ? row.coupon.couponCode : "Coupon Not Applied",
      cell: (row) => (
        <span style={{ color: row.coupon ? "inherit" : "#6c757d" }}>
          {row.coupon ? row.coupon.couponCode : "Coupon Not Applied"}
        </span>
      ),
    },
    {
      name: "Price",
      selector: (row) => (row.price ? row.price.price : "N/A"),
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <span className={`font-bold ${getPaymentStatusColor(row.paymentStatus)}`}>
          {row.paymentStatus.charAt(0).toUpperCase() + row.paymentStatus.slice(1)}
        </span>
      ),
    },
    {
      name: "Audio",
      selector: (row) =>
        row.audioUrl ? (
          <button
            onClick={() => handlePlayClick(row.audioUrl)}
            className="flex items-center text-blue-600"
          >
            <Music className="mr-1" />
            Play
          </button>
        ) : (
          <span className="text-gray-500">N/A</span>
        ),
    },
  ];

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <Card extra={"mt-4 !z-5 overflow-hidden"}>
        <div className="flex flex-col gap-4 h-fit w-full rounded-t-2xl bg-white px-4 pb-[20px] pt-4 shadow-2xl shadow-gray-100 dark:!bg-navy-700 dark:shadow-none">

          <h4 className="text-lg font-bold text-navy-700 dark:text-white">All Sessions</h4>
          <div className="flex gap-4 items-center">
            <input
              type="text"
              placeholder="Filter by email"
              className="p-2 border border-gray-300 rounded-md w-48 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out shadow-sm hover:shadow-lg"
              value={emailFilter}
              onChange={(e) => setEmailFilter(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded-md w-48 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out shadow-sm hover:shadow-lg"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">Filter by Status</option>
              <option value="paid">Paid</option>
              <option value="unpaid">Unpaid</option>
            </select>
          </div>


        </div>

        <DataTable
          loadingMessage="Loading..."
          isLoading={loading}
          columns={columns}
          data={filteredSessions}
          noDataMessage={<p className="my-5 py-5">No Data found!</p>}
        />

        <div className="mt-4 flex justify-end mb-4">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={totalPages < 2 ? 2 : totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"flex items-center"}
            pageClassName={"mx-1"}
            pageLinkClassName={
              "px-3 border border-gray-300 rounded-md hover:bg-blue-400"
            }
            previousClassName={"mx-1"}
            previousLinkClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:bg-blue-100"
            }
            nextClassName={"mx-1"}
            nextLinkClassName={
              "px-3 py-1 border border-gray-300 rounded-md hover:bg-blue-100"
            }
            activeClassName={"bg-blue-500 text-white"}
            disabledClassName={"opacity-50 cursor-not-allowed"}
          />
        </div>
      </Card>

      <AudioPlayerModal
        isOpen={isModalOpen}
        onClose={closeModal}
        audioUrl={currentAudioUrl}
      />
    </>
  );
};

export default AllAffirmations;
