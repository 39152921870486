import React from "react";
import { FaTimes } from "react-icons/fa"; // Importing the 'FaTimes' icon

const AudioPlayerModal = ({ isOpen, onClose, audioUrl }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm"
      onClick={onClose}
    >
      <div
        className="relative w-full max-w-lg bg-white rounded-lg shadow-2xl p-6 transform transition-all scale-95 sm:scale-100"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 text-xl focus:outline-none"
          onClick={onClose}
          aria-label="Close"
        >
          <FaTimes /> {/* Using FaTimes icon here */}
        </button>
        <h2 className="text-xl font-semibold text-gray-900 mb-4 text-center">
          Audio Player
        </h2>
        <div className="flex justify-center mb-4">
          <audio controls className="w-full rounded-md shadow-md">
            <source src={audioUrl} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayerModal;
